// font type
@mixin font($type: sans-serif) {
	font-family: $type, sans-serif;
}

// font sizing convert to rem
@mixin font-size($sizeValue) {
	$font-size : $sizeValue / $base-font-size;
	font-size: $font-size + rem;
}

@mixin button() {
	appearance: none;
	background: none;
	cursor: pointer;
	border: 1px solid $black;
	height: 40px;
	min-width: 150px;
	transition: all .2s linear;
}