.spinner {
	animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(270deg);
	}
}

.path {
	stroke-dasharray: 187;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
	0% {
		stroke: #000000;
	}
	25% {
		stroke: #170100;
	}
	50% {
		stroke: #200000;
	}
	75% {
		stroke: #4e0101;
	}
	100% {
		stroke: #670000;
	}
}
@keyframes dash {
	0% {
		stroke-dashoffset: 187;
	}
	50% {
		stroke-dashoffset: 46.75;
		transform: rotate(135deg);
	}
	100% {
		stroke-dashoffset: 187;
		transform: rotate(450deg);
	}
}