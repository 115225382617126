@font-face {
	font-family: 'bold';
	src: url('../fonts/Oswald/Bold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'semibold';
	src: url('../fonts/Oswald/SemiBold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'regular';
	src: url('../fonts/Oswald/Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'light';
	src: url('../fonts/Oswald/Light.ttf') format('truetype');
	font-display: swap;
}
